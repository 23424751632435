<script setup lang="ts">
import type { HouseSlug, Locale } from 'lc-services/types'
import type { House } from '~/server/types/home'

const props = defineProps<{
  house: House & {
    slug: HouseSlug
    destination: {
      slug: HouseSlug
      name: string
      published: boolean
    }
  }
}>()

const localePath = useLocalePath()
const { locale } = useI18n()
const { trackEvent } = useTracking()

const getDestiHref = (slug: HouseSlug) => {
  const slugArr = slug[locale.value as Locale].split('/')
  const name = slugArr[slugArr.length - 1]
  const destination = slugArr[0]

  return localePath({
    name: 'destination-name',
    params: {
      name,
      destination,
    },
  })
}

const trackBigHouse = () => {
  trackEvent({
    event: 'promo_list_click',
    label: props.house.name,
    product_list_title: undefined,
    product_list_subtitle: undefined,
  })
}
</script>

<template>
  <figure>
    <NuxtLink
      :to="
        localePath({
          name: 'house-slug',
          params: { slug: house.slug[$i18n.locale as Locale] },
        })
      "
      class="home-big-img__img-wrapper"
    >
      <BaseNuxtImg
        :alt="$t('home.big_house_alt')"
        :src="house.img"
        aspect-ratio="16/9"
        loading="lazy"
        @click="trackBigHouse"
      />
    </NuxtLink>
    <figcaption class="home-big-img__figcaption">
      <BaseButton
        :to="
          localePath({
            name: 'house-slug',
            params: { slug: house.slug[$i18n.locale as Locale] },
          })
        "
        color="secondary"
        variant="link"
        @click="trackBigHouse"
      >
        {{ house.name }},&nbsp;
      </BaseButton>
      <BaseButton
        :to="getDestiHref(house.destination.slug)"
        color="secondary"
        variant="link"
      >
        {{ house.destination.name }}
      </BaseButton>
    </figcaption>
  </figure>
</template>

<style>
.home-big-img__img-wrapper {
  @apply block;
  margin: 0 0 1rem 0;
  height: 386px;
}

@screen md {
  .home-big-img__img-wrapper {
    height: 586px;
  }
}

@screen lg {
  .home-big-img__img-wrapper {
    height: 650px;
  }
}

@screen xl {
  .home-big-img__img-wrapper {
    height: 70vh;
  }
}

.home-big-img__figcaption {
  @apply text-center;
}
</style>
