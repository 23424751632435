<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const props = defineProps<{
  bigImage: { slug: Record<Locale, string>; img: string }
  page: string
}>()

const { locale, t } = useI18n()
const localePath = useLocalePath()

const ctaLinks = ref([
  {
    id: 1,
    img: '/production/uploads/photos/activity-725/2019-08-29-6402648c562ced858113d64f91d0b692.jpg',
    path: localePath('houseCollection'),
    label: t(`global.selection.label${1}`),
  },
  {
    id: 2,
    img: '/production/uploads/photos/activity-725/2019-08-29-3b50024da67574f704acf6c6b98a3800.jpg',
    path: localePath('search'),
    label: t(`global.selection.label${3}`),
  },
  {
    id: 3,
    img: '/production/uploads/photos/activity-725/2019-08-29-30446884876d02f7944cb7d675e58a9f.jpg',
    path: localePath('owners'),
  },
])

const path = computed(() => {
  let path = ''

  if (props.bigImage.slug) {
    path = localePath({
      name: 'house-slug',
      params: { slug: props.bigImage.slug[locale.value as Locale] },
    })
  }

  return path
})
</script>

<template>
  <base-container class="selection-block">
    <NuxtLink :to="path" class="selection-block__big-image-container">
      <BaseNuxtImg
        :alt="$t('global.selection.alt_img')"
        :src="bigImage.img"
        height="563"
        loading="lazy"
        width="1200"
      />
    </NuxtLink>

    <base-row class="selection-block__links">
      <base-col
        v-for="item in ctaLinks"
        :key="item.id"
        cols="24"
        md="12"
        xl="8"
        class="selection-block__col"
      >
        <nuxt-link :to="item.path" class="selection-block__cta">
          <div class="selection-block__cta-img">
            <BaseNuxtImg
              :alt="$t(`global.selection.alt${item.id}`)"
              :src="item.img"
              height="80"
              loading="lazy"
              width="80"
            />
          </div>

          <p
            class="text-lg selection-block__cta-title text-font-family-serif selection-block__cta-text"
          >
            {{ $t(`global.selection.title${item.id}`) }}
          </p>
          <p class="selection-block__cta-label">
            {{ $t(`global.selection.label${item.id}`) }}
          </p>
        </nuxt-link>
      </base-col>
    </base-row>
  </base-container>
</template>

<style>
.selection-block {
  margin: 3rem auto 0 auto;
}

.selection-block__big-image-container {
  @apply block;
  margin: 0 0 2.75rem 0;
  height: 400px;
}

@screen md {
  .selection-block__big-image-container {
    margin: 0 0 2.5rem 0;
    height: 630px;
  }
}

@screen lg {
  .selection-block__big-image-container {
    height: 563px;
  }
}

.selection-block__col {
  margin: 0 0 2rem 0;
}
.selection-block__col:last-child {
  @apply m-0;
}

@screen md {
  .selection-block__col {
    margin: 0 0 2rem 0;
  }
}

@screen xl {
  .selection-block__col {
    @apply m-0;
  }
}

.selection-block__cta {
  min-height: 68px;
  @apply text-gray-700 block relative;
}
.selection-block__cta:hover,
.selection-block__cta:focus,
.selection-block__cta:active {
  @apply text-gray-700 no-underline;
}

@screen md {
  .selection-block__cta {
    min-height: 80px;
  }
}

.selection-block__cta-img {
  @apply absolute;
  width: 68px;
  height: 68px;
  border-radius: 0.125rem;
  margin: 0 1rem 0 0;
}

@screen md {
  .selection-block__cta-img {
    width: 80px;
    height: 80px;
  }
}

.selection-block__cta-text,
.selection-block__cta-title,
.selection-block__cta-label {
  padding: 0 0 0 calc(68px + 1rem);
  @apply m-0;
}

@screen md {
  .selection-block__cta-text,
  .selection-block__cta-title,
  .selection-block__cta-label {
    padding: 0 0 0 calc(80px + 1.5rem);
  }
}

@screen md {
  .selection-block__cta-title {
    margin: 0 0 0.25rem 0;
  }
}

.selection-block__cta-label {
  @apply text-secondary-500 font-medium;
}
</style>
