<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import type { House } from '~/server/types/home'

type ExcluHouse = House & {
  slug: Record<Locale, string>
  destination: {
    name: string
    published: boolean
  }
}
defineProps<{
  exclus: ExcluHouse[]
}>()
const { locale } = useI18n()
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const swiperOptions = {
  breakpoints: {
    576: {
      slidesPerView: 1.3,
    },
    768: {
      slidesPerView: 1.5,
      spaceBetween: 24,
    },
    992: {
      slidesPerView: 1.7,
      spaceBetween: 24,
    },
    1440: {
      slidesPerView: 2.2,
      spaceBetween: 24,
    },
    2000: {
      slidesPerView: 2.7,
      spaceBetween: 24,
    },
  },
  centeredSlides: false,
  loop: true,
  slidesPerView: 1.1,
  spaceBetween: 16,
}

const excluTitle = (exclu: ExcluHouse) => {
  let excluTitle = `${exclu.name}`

  if (exclu?.destination?.name) {
    excluTitle += `, ${exclu.destination.name}`
  }

  return excluTitle
}
const excluUrl = (exclu: ExcluHouse) => {
  return localePath({
    name: 'house-slug',
    params: { slug: exclu.slug[locale.value as Locale] },
  })
}
const trackHomeHouse = (exclu: ExcluHouse) => {
  trackEvent({
    event: 'promo_list_click',
    label: exclu.name,
    product_list_title: 'The Iconic Collection',
    product_list_subtitle: undefined,
  })
}
</script>

<template>
  <div>
    <section>
      <VirtualBaseCarousel
        class-name="exclus-block__carousel"
        :slides="exclus"
        :navigation="$device.isDesktop"
        :swiper-options="swiperOptions"
        :virtual="false"
      >
        <template #slider-content="{ slide }">
          <div class="exclus-block__carousel-item">
            <nuxt-link
              class="text-gray-700 hover:no-underline hover:text-gray-700"
              :to="excluUrl(slide as ExcluHouse)"
              @click="trackHomeHouse(slide as ExcluHouse)"
            >
              <base-media-object direction="flex-col">
                <template #image>
                  <div class="h-[250px] md:h-[450px]">
                    <BaseNuxtImg
                      :alt="slide.name"
                      :src="slide.photo"
                      sizes="sm:100vw md:50vw lg:50vw xl:50vw xxl:50vw"
                      loading="lazy"
                      aspect-ratio="4/3"
                    />
                  </div>
                </template>
                <template #content>
                  <div>
                    <h3
                      class="px-6 pt-6 text-2xl md:pt-8 md:px-12 lg:pt-10 lg:px-14"
                    >
                      {{ excluTitle(slide as ExcluHouse) }}
                    </h3>
                    <p
                      class="px-6 pt-2 overflow-hidden exclus-block__text md:pt-4 md:px-12 lg:pt-6 lg:px-14 overflow-ellipsis"
                    >
                      {{ slide.text }}
                    </p>
                  </div>
                </template>
              </base-media-object>
            </nuxt-link>
          </div>
        </template>
      </VirtualBaseCarousel>
    </section>
  </div>
</template>

<style>
.exclus-block__carousel {
  @apply relative mt-16;
}

.exclus-block__carousel-item {
  max-height: 800px;
}

.exclus-block__text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.swiper.exclus-block__carousel
  .carousel-arrow__wrap.carrousel-arrow__wrap--next {
  right: 0.9rem;
}

.swiper.exclus-block__carousel
  .carousel-arrow__wrap.carrousel-arrow__wrap--prev {
  left: 0.9rem;
}

.swiper.exclus-block__carousel .carousel-arrow__wrap {
  top: calc(50% - 85px);
}
</style>
