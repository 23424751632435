<script setup lang="ts">
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { locale, t } = useI18n()

const searchPath = localePath({
  name: 'search',
  // @todo check if this facet name is correct
  query: {
    facetFilter: `searchable_tags.5.${locale.value}: ${t('global.hotdeal')}`,
  },
})

const onClickHotDeals = () =>
  trackEvent({
    event: 'homepage_favourites_click',
    suggestion: 'house',
  })
</script>

<template>
  <BaseContainer class="bg-gray-100 lg:max-w-full pt-8 pb-8">
    <BaseRow class="lg:justify-center lg:items-center">
      <BaseCol cols="24" md="12" sm="24">
        <BaseSectionContent
          id="hot-deals"
          :title="$t('home.hotdeals.title')"
          :subtitle="$t('home.hotdeals.text')"
          class=""
        >
          <template #header>
            <BaseButton
              class="text-lg items-center"
              color="secondary"
              size="xl"
              :to="searchPath"
              @click.prevent="onClickHotDeals"
            >
              {{ $t('home.hotdeals.link') }}
            </BaseButton>
          </template>
        </BaseSectionContent>
      </BaseCol>
      <BaseCol cols="24" md="12" sm="24" class="sm:pt-0 pt-8">
        <BaseNuxtImg
          class="lg:p-20"
          src="production/assets/images/homepage/last-minute.jpg"
          width="547"
          height="365"
          alt="Last minute Hot Deals"
        />
      </BaseCol>
    </BaseRow>
  </BaseContainer>
</template>
